.header {
  width: 100vw;
  height: 3.25rem;
  min-height: 8%;
  text-decoration: none;
  background-color: #666;
}

.header--nav {
  display: inline;
  text-align: center;
  vertical-align: middle;
}
