.component-view-card {
  width: 300px;
  min-height: 300px;
  overflow: hidden;
  border: 2px solid #000;
  box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 15%);
  transition: 0.2s;
}


.component-view-card:hover {
  box-shadow: 5px 5px 14px 7px rgb(0 0 0 / 15%);
  transition: 0.2s;
  transform: scale(1.05);
}

.component-view-card:active {
  box-shadow: none;
  transition: 0.05s;
  transform: scale(1.02);
}

.component-view-card--rounded {
  border-radius: 12px;
}

.component-view-card--view-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  overflow: hidden;
}
