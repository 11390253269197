main {
  min-height: 85vh;
  padding-top: 40px;
  padding-bottom: 80px;
}

.main--inner {
  width: min(90%, 1280px);
  margin-right: auto;
  margin-left: auto;
}

a {
  text-decoration: none;
}
